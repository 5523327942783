<template>
  <div class="qiugou">
    <el-col :span="4">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="今日求购" name="first"></el-tab-pane>
        <el-tab-pane label="历史求购" name="second"></el-tab-pane>
      </el-tabs>
      <el-col
        :span="24"
        v-for="(item,index) in content"
        :key="index"
        class="orderItem"
        :class="{'channgeColor':item.id == currentOrderId}"
      >
        <el-col :span="24">
          <div @click="choose(index,item)">
            <div>
              <p class="name">{{ item.name }}</p>
              <p class="createTime">{{ item.createTimeFormat }}</p>
              <p class="status">
                <i class="leftAngle"></i>
                <span class="statusText">{{ item.status }}</span>
              </p>
            </div>
          </div>
        </el-col>
      </el-col>
      <el-col :span="24" v-if="content.length === 0">
        <p class="noOrder">暂无订单</p>
      </el-col>
    </el-col>
    <el-col :span="18" :offset="1">
      <el-col :span="24">
        <div class="qiugouSteps">
          <el-steps :active="active">
            <el-step title="发布求购"></el-step>
            <el-step title="卖家报价"></el-step>
            <el-step title="提交采购意向"></el-step>
            <el-step title="卖家接单交易"></el-step>
            <el-step title="完成交易"></el-step>
          </el-steps>
        </div>
      </el-col>
      <el-col :span="24">
        <el-col :span="10" class="baojiaDetails">
          <!-- <el-col :span="24">报价明细：{{ detail.enterpriseName }}</el-col> -->
          <el-col :span="24">求购：{{ detail.name }} 地区要求：{{ detail.origin }}</el-col>
        </el-col>
        <el-col :span="6" :offset="8">
          <el-col :span="12" :offset="12">
            <el-button
              class="cancelQiugou"
              :disabled="!currentOrderId"
              type="danger"
              @click="cancelOrder()"
            >取消求购</el-button>
          </el-col>
          <!-- <el-col :span="12">
            <div class="btn1">延时</div>
          </el-col>-->
        </el-col>
      </el-col>
      <el-col :span="24">
        <p class="baojiaTabTitle">
          <span>已报价</span>
        </p>
        <el-table :data="tableData">
          <el-table-column label="品名" prop="name"></el-table-column>
          <el-table-column label="数量" prop="quantity"></el-table-column>
          <el-table-column label="价格" prop="orderPrice"></el-table-column>
          <el-table-column label="地区要求" prop="origin"></el-table-column>
          <el-table-column label="渠道" prop="resourceChannel"></el-table-column>
          <el-table-column prop="address" label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="handleBuy(scope.$index, scope.row)">采购意向</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="paginationBaojia"
          @size-change="handleSizeChangeBaojia"
          @current-change="handleCurrentChangeBaojia"
          :current-page="currentBaojia"
          :page-size="pageSizeBaojia"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPageBaojia"
        ></el-pagination>
      </el-col>
    </el-col>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
      isActive: true,
      activeName: "first",
      tableData: [],
      today: [],
      yesterday: [],
      content: [],
      detail: {},
      pageSizeBaojia: 10,
      currentBaojia: 1,
      totalPageBaojia: null,
      currentOrderId: null
    };
  },
  mounted() {
    this.param_queryMyBuyOrderOverview();
  },
  methods: {
    handleSizeChangeBaojia(val) {
      this.pageSizeBaojia = val;
      this.param_sellerOrderOfferDetails(this.currentOrderId);
    },
    handleCurrentChangeBaojia(val) {
      this.currentBaojia = val;
      this.param_sellerOrderOfferDetails(this.currentOrderId);
    },
    param_queryMyBuyOrderOverview() {
      http
        .postFront(protocolFwd.param_queryMyBuyOrderOverview)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.today = value.today;
            this.yesterday = value.history;
            this.content = value.today;
            for (let i = 0; i < this.content.length; i++) {
              this.content[i].createTimeFormat = global.util.DateFormate(
                this.content[i].createTime
              );
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClick(tab, event) {
      if (this.activeName == "first") {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.today;
      } else {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.yesterday;
      }
    },
    choose(index, item) {
      this.currentOrderId = item.id;
      this.param_queryMyOrderDetails(item.id);
      this.param_sellerOrderOfferDetails(item.id);
    },
    param_queryMyOrderDetails(id) {
      protocolFwd.param_queryMyOrderDetails.param.orderId = id;
      http.postFront(protocolFwd.param_queryMyOrderDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.detail = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    param_sellerOrderOfferDetails(id) {
      if (!id) {
        return false;
      }
      protocolFwd.param_sellerOrderOfferDetails.param.page =
        this.currentBaojia - 1;
      protocolFwd.param_sellerOrderOfferDetails.param.size = this.pageSizeBaojia;
      protocolFwd.param_sellerOrderOfferDetails.param.orderId = id;
      http
        .postFront(protocolFwd.param_sellerOrderOfferDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.tableData = value.content;
            this.totalPageBaojia = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    cancelOrder() {
      protocolFwd.param_undoOrder.param.orderId = this.detail.id;
      http.postFront(protocolFwd.param_undoOrder).then(response => {
        const { code, message, data } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleBuy(index, item) {
      this.$confirm("确认求购？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_confirmOrder.param.offerId = item.offId;
          protocolFwd.param_confirmOrder.param.commodityId = item.commodityId;
          http.postFront(protocolFwd.param_confirmOrder).then(response => {
            const { code, message, data } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$router.push("/matching/dealOrder");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.qiugou {
  overflow: hidden;
}
.qiugou .baojiaTabTitle {
  margin: 10px 0;
  border-bottom: 1px solid #eeeeee;
}
.qiugou .baojiaTabTitle span {
  display: inline-block;
  padding: 0 15px;
  font-size: 16px;
  border-bottom: 2px solid $commonThemeColor;
}
.qiugou .qiugouSteps {
  margin-top: 10px;
}
.qiugou .baojiaDetails {
  margin: 10px 0;
  line-height: 35px;
}
.qiugou .cancelQiugou {
  margin-top: 10px;
}
.qiugou .orderItem {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  line-height: 22px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.qiugou .orderItem .createTime {
  color: #666666;
}
.qiugou .orderItem .status {
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 20px;
}
.qiugou .orderItem .status .leftAngle {
  float: left;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #f4a83c transparent transparent;
}
.qiugou .orderItem .status .statusText {
  float: left;
  display: block;
  padding: 0 8px 0 5px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  background-color: #f4a83c;
}
.qiugou .orderItem.channgeColor {
  border: 1px solid orange;
}
.qiugou .orderItem .status {
  position: absolute;
  top: 5px;
  right: 0;
}
.qiugou .noOrder {
  margin: 10px 0;
  text-align: center;
  color: #666666;
}
.qiugou .smallPaginationCol {
  text-align: center;
}
.qiugou .paginationBaojia {
  margin-top: 15px;
}
.active {
  border: 1px solid orange;
}
.btn {
  background: orange;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.btn1 {
  width: 90%;
  height: 20px;
  border: 1px solid gray;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
}
</style>
